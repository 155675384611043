import Vue from 'vue';
import App from './App.vue';
import router from './routes/router';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSweetalert2 from 'vue-sweetalert2';
import moment from 'moment';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import VueMask from 'v-mask';
import VuePreferences from 'vue-preferences';
import VueMeta from 'vue-meta';
import VueSocialSharing from 'vue-social-sharing';
import VueI18n from 'vue-i18n';
import { messages } from './traduction';
import browserDetect from "vue-browser-detect-plugin";
import Embed from 'v-video-embed';
import VueClazyLoad from 'vue-clazy-load';

/**********************************/
/** Vue.use section **/
/**********************************/
// Vue.use(VueAwesomeSwiper) ;
Vue.use(VueMask) ;
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue) ;
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin) ;
// Axios
Vue.use(VueAxios, axios) ;
// SweetAlert2
Vue.use(VueSweetalert2) ;
// Preferences
Vue.use(VuePreferences) ;
// Pusher Config
Vue.use(require('vue-pusher'), {
    api_key: '84a8f65d09f20f4a5c67',
    options: { cluster: 'eu', }
});
// Format Date
Vue.filter('formatDateJour', function (value) {
    if (value) {
        moment.locale('fr')
        return moment(String(value)).format('ddd DD MMM YYYY')
        //, h:mm a
    }
}) ;
// Filter
Vue.filter('formatDate', function (value) {
    if (value) {
        moment.locale('fr')
        return moment(String(value)).format('llll')
        //, h:mm a
    }
}) ;
// Filter montant
Vue.filter("formatAmount", function(value){

    if (!value) return "";
    let formatedValue = new Intl.NumberFormat().format(value)

    return formatedValue;
})
// Meta Data
Vue.use(VueMeta) ;
// Social Sharing
Vue.use(VueSocialSharing) ;
// Traduction
Vue.use(VueI18n) ;
const i18n = new VueI18n({
    locale: (localStorage.getItem('lang') || 'fr'),
    messages,
});
// Vue Detection browser
Vue.use(browserDetect);
// Video embed
Vue.use(Embed);
//CLazy load
Vue.use(VueClazyLoad);
// Config productionTip
Vue.config.productionTip = false ;
/**********************************/
/** Vue.use section **/
/**********************************/


/**********************************/
/** Require section **/
/**********************************/
require('@/assets/vendors/@fortawesome/fontawesome-free/css/all.min.css') ;
require('@/assets/vendors/themify-icons/themify-icons.css') ;
require('@/assets/vendors/line-awesome/css/line-awesome.min.css') ;
require('@/assets/css/app.css') ;
require('@/assets/css/custom.css') ;
require('@/assets/css/paiement.css') ;
require('@/assets/vendors/feather-icons/feather.css') ;
require('@/assets/vendors/perfect-scrollbar/css/perfect-scrollbar.css') ;
require('@/assets/vendors/DataTables/datatables.min.css') ;
/**********************************/
/** Require section **/
/**********************************/


/**********************************/
/** Other section **/
/**********************************/

/**********************************/
/** Other section **/
/**********************************/

new Vue({
    router, i18n, render: h => h(App)
}).$mount('#app') ;

