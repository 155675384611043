import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	// {
	//     path: "/test-orabank/:slug?",
	//     name: "testFormOrabank",
	//     component: () => import("../pages/TestOrabank"),
	//     meta: {
	//         title: 'Comme tu veux',
	//         metaTags: [
	//             {
	//                 name: 'description',
	//                 content: 'Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.'
	//             },
	//             {
	//                 property: 'og:description',
	//                 content: 'Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.'
	//             }
	//         ]
	//     },
	// },
	{
		path: "/:slug?",
		name: "defaultPage",
		component: () => import("../pages/DefaultPage"),
		meta: {
			title: "Comme tu veux",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},
	{
		path: "/:slug?/m=:montant?",
		name: "defaultPage_",
		component: () => import("../pages/DefaultPage"),
		meta: {
			title: "Comme tu veux",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},
	{
		path: "/:slug?/m=:montant?/r=:reason?",
		name: "_defaultPage_",
		component: () => import("../pages/DefaultPage"),
		meta: {
			title: "Comme tu veux",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},
	{
		path:
			"/:slug?/m=:montant?/i=:indice?/t=:tel?/n=:nom?/p=:prenom?/e=:email?/r=:reason?",
		name: "defaultPage__",
		component: () => import("../pages/DefaultPage"),
		meta: {
			title: "Comme tu veux",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},
	{
		path:
			"/:slug?/m=:montant?/i=:indice?/t=:tel?/n=:nom?/p=:prenom?/e=:email?/r=:reason?/k=:key?",
		name: "defaultPage__2",
		component: () => import("../pages/DefaultPage"),
		meta: {
			title: "Comme tu veux",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},
	{
		path:
			"/:slug?/m=:montant?/i=:indice?/t=:tel?/n=:nom?/p=:prenom?/e=:email?/r=:reason?/k=:key?/l=:redirect_key?",
		name: "defaultPage__3",
		component: () => import("../pages/DefaultPage"),
		meta: {
			title: "Comme tu veux",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},
	{
		path:
			"/:slug?/m=:montant?/i=:indice?/t=:tel?/n=:nom?/p=:prenom?/e=:email?/r=:reason?/k=:key?/l=:redirect_key?/u=:url_redirect?",
		name: "defaultPage__4",
		component: () => import("../pages/DefaultPage"),
		meta: {
			title: "Comme tu veux",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},
	{
		path:
			"/:slug?/m=:montant?/i=:indice?/t=:tel?/n=:nom?/p=:prenom?/e=:email?/r=:reason?/k=:key?/l=:redirect_key?/s=:store_id?/sb=:slug_boutique?",
		name: "shop_1",
		component: () => import("../pages/DefaultPage"),
		meta: {
			title: "Comme tu veux",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},
	{
		path:
			"/:slug?/m=:montant?/i=:indice?/t=:tel?/n=:nom?/p=:prenom?/e=:email?/r=:reason?/k=:key?/l=:redirect_key?/f=:formule?",
		name: "e_ticket",
		component: () => import("../pages/DefaultPage"),
		meta: {
			title: "Comme tu veux",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},
	{
		path:
			"/:slug?/m=:montant?/i=:indice?/t=:tel?/n=:nom?/p=:prenom?/e=:email?/r=:reason?/k/l=:redirect_key?/f=:formule?",
		name: "e_ticket_without_key",
		component: () => import("../pages/DefaultPage"),
		meta: {
			title: "Comme tu veux",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},
	{
		path:
			"/:slug?/m=:montant?/i=:indice?/t=:tel?/n=:nom?/p=:prenom?/e=:email?/r=:reason?/l=:redirect_key?/f=:formule?",
		name: "e_ticket_without_key_alias",
		component: () => import("../pages/DefaultPage"),
		meta: {
			title: "Comme tu veux",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},
	{
		path: "/nourahama/:slug?",
		name: "defaultPage___",
		component: () => import("../pages/DefaultPage"),
		meta: {
			title: "Comme tu veux",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},
	{
		path: "/ref/:reference?",
		name: "refPage",
		component: () => import("../pages/ReferencePage"),
		meta: {
			title: "Comme tu veux",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},
	{
		path: "/return-payment/:reference?",
		name: "returnPaymentPage",
		component: () => import("../pages/ReturnPaymentPage"),
		meta: {
			title: "Comme tu veux",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},
	{
		path: "/return-payment/:reference?/r=:message?",
		name: "returnPaymentPage2",
		component: () => import("../pages/ReturnPaymentPage"),
		meta: {
			title: "Comme tu veux",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},
	{
		path: "/payment/:slug?/:name?",
		component: () => import("../pages/PaymentPage"),
	},
	{
		path: "/recu/:ref?",
		name: "receiptPayment",
		component: () => import("../pages/Receipt"),
		meta: {
			title: "Voilà mon reçu!",
			metaTags: [
				{
					name: "description",
					content:
						"Téléchargez votre reçu pour le paiement effectué via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Téléchargez votre reçu pour le paiement effectué via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},
	{
		path: "/business/:numero?",
		name: "mutilbusinessPage",
		component: () => import("../pages/mutilbusinessPage"),
		meta: {
			title: "Retrouve mes liens de paiement ici",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez cliquer sur mon lien pour me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez cliquer sur mon lien pour me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},
	{
		path: "/a/:reference?",
		name: "payerAvecAppAPaym",
		component: () => import("../pages/payWithAPaymPage"),
		meta: {
			title: "Comme tu veux",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez cliquer sur mon lien pour me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez cliquer sur mon lien pour me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},
	{
		path: "/orabank/form-dev",
		name: "orabankFormulaire",
		component: () => import("../pages/FormulaireCartePage"),
		meta: {
			title: "Formulaire de test - Orabank APIs",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez cliquer sur mon lien pour me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez cliquer sur mon lien pour me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},
	/*{
		  path: "/sgi-form/:slug?",
		  name: "nsiaFinanceFormulaire",
		  component: () => import("../pages/NSIAFinancePage"),
		  meta: {
			  title: 'Formulaire NSIA Finance - 2e proposition',
			  metaTags: [
				  {
					  name: 'description',
					  content: 'Veuillez cliquer sur mon lien pour me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.'
				  },
				  {
					  property: 'og:description',
					  content: 'Veuillez cliquer sur mon lien pour me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.'
				  }
			  ]
		  },
	  },*/
	{
		path: "*",
		name: "pageErreur",
		component: () => import("../pages/Erreur404"),
		meta: {
			title: "Ooops !! La page n'existe pas.",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez cliquer sur mon lien pour me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez cliquer sur mon lien pour me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},

	{
		path: "/demo/:slug?",
		name: "demoFormulaire",
		component: () => import("../pages/FormulaireDemo"),
		meta: {
			title: "Comme tu veux",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},

	{
		path: "/service/transfert",
		name: "serviceTransfert",
		component: () => import("../pages/services/Transfert"),
		meta: {
			title: "Comme tu veux",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},

	{
		path: "/service/response/:reference?",
		name: "serviceResponsePayment",
		component: () => import("../pages/services/ResponsePayment"),
		meta: {
			title: "Comme tu veux",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},

	// SOUSCRIPTION ASSURANCE
	{
		path: "/souscription/:slug_marchand",
		name: "SouscriptionAssurance",
		component: () => import('../pages/SouscriptionAssurance.vue'),
		meta: {
			title: "Comme tu veux",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},

	{
		path: "/souscription/:slug_marchand/assurance-civile",
		name: "SouscriptionAssuranceCivile",
		component: () => import('../pages/SouscriptionAssuranceCivile.vue'),
		meta: {
			title: "Comme tu veux",
			metaTags: [
				{
					name: "description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
				{
					property: "og:description",
					content:
						"Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym.",
				},
			],
		},
	},
];

const router = new VueRouter({
	routes,
	mode: "history",

	scrollBehavior(to, from, SavedPosition) {
		return SavedPosition || {top: 0};
	},
});

router.beforeEach((to, from, next) => {
	document.title = to.meta.title;
	next();
});

router.afterEach((to) => {
	localStorage.setItem("route", to.name);
});

export default router;
